<template>
    <div align="center">
        <br><br>

        <div class="row justify-center">
            <div class="col-12 col-md-10">
                <QQButton label="Pratiche Definite" color="blue-grey" icon="mdi-clipboard-check-multiple-outline" size="xl" @click.native="onPraticheDefinite()"/>
                <QQButton label="Pratiche caricate" color="blue-grey" icon="mdi-tow-truck" size="xl" @click.native="onPraticheCaricate()"/>
                <QQButton label="Scadenze Contrattuali" color="blue-grey" icon="mdi-alarm" size="xl" @click.native="onScadenzeContrattuali()"/>
                <QQButton label="Scadenze Semestrali" color="blue-grey" icon="mdi-alarm" size="xl" @click.native="onScadenzeSemestrali()"/>
                <QQButton label="Portafoglio attivo" color="blue-grey" icon="mdi-fire" size="xl" @click.native="onPortafoglioAttivo()"/>
            </div>

            <br><br><br><br><br>
        </div>

    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    import policy from "@/libs/policy";
    //import commonLib from "@/libs/commonLib";
    import {mapFields} from "vuex-map-fields"
    import { mapState, mapActions } from "vuex";

    export default {
        name: "MenuGestionePratiche",
        data() {
            return {
                annotazione: ""
            }
        },
        components: {
            QQButton
        },
        computed: {
            ...mapFields("gestionePratiche", {

            }),
            ...mapState({
                formPratica: state => state.gestionePratiche.formPratica
            }),
            ...mapFields('formPreventivo', [
                "step"
            ]),
            isSede() {
                return policy.isSede();
            }
        },
        methods: {
            ...mapActions({
            //    fetchDatiCliente: "gestionePratiche/fetchDatiClientePratica",
            //    setAnnullaPratica: "gestionePratiche/setAnnullaPratica",
            //    fetchNotePratica: "gestionePratiche/fetchNotePratica",
            //    fetchDatiPratica: "gestionePratiche/fetchDatiPratica",
            //    clearElencoDocumentiPratica: "gestioneDocumenti/clearElencoDocumentiPratica"
            }),
            onPraticheDefinite() {
                this.$router.push({name : "Estrazioni.PraticheDefinite"});
            },
            onPraticheCaricate() {
               this.$router.push({name : "Estrazioni.PraticheCaricate"});
            },
            onGestioneFatture() {
                alert("In costruzione");
            },
            onScadenzeContrattuali() {
                this.$router.push({name : "Estrazioni.ScadenzeContrattuali"});
            },
            onScadenzeSemestrali() {
                this.$router.push({name : "Estrazioni.ScadenzeInteranno"});
            },
            onPortafoglioAttivo() {
                this.$router.push({name : "Estrazioni.PortafoglioAttivo"});
            }
        },
        async mounted() {
        },
        async activated() {
        }
    }
</script>
